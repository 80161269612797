<template>
  <div class="pb-2">
    <h4 class="text-center font-medium-5">
      <!--      {{ $t('Role List') }}-->
    </h4>
    <l-table
      ref="lTableRef"
      :module-name="MODULE_NAME"
      :table-columns="tableColumns"
      :create-page-url="{ name: 'settings-users-role-create' }"
      trash-list-path="settings-users-role-trash-list"
      :can-create="ACCESS_ABILITY_FOR_ROLE"
      :is-searchable="false"
      :table-config-options="{
        endpoint: `${MODULE_NAME}/getActiveRolesList`
      }"
      :can-show-trash-list="ACCESS_ABILITY_FOR_ROLE"
    >
      <template #cell(is_active)="{ data }">
        {{ $t(data.value ? 'Active': 'Inactive') }}
      </template>
      <template #cell(actions)="{ data }">
        <div class="text-nowrap">
          <Can
            :i="ACCESS_ABILITY_FOR_ROLE.action"
            :a="ACCESS_ABILITY_FOR_ROLE.subject"
          >
            <feather-icon
              v-b-tooltip.noninteractive.hover.bottom="$t('Set Inactive')"
              icon="LTrashIconKits"
              size="32"
              class="mr-1 cursor-pointer"
              @click="remove(data.item)"
            />
            <feather-icon
              v-b-tooltip.noninteractive.hover.bottom="$t('View')"
              icon="LStockEyeIcon"
              class="mr-1 cursor-pointer"
              size="32"
              @click="gotoNewPage({ name: 'settings-users-role-update', params: { id: data.item.id } }, $event)"
            />
            <feather-icon
              v-b-tooltip.noninteractive.hover.bottom
              icon="LCopyIconKits"
              size="32"
              class="cursor-pointer"
              :title="$t('Copy')"
              @click="duplicate(data.item)"
            />
          </Can>
        </div>
      </template>
    </l-table>
  </div>
</template>

<script>

import { VBTooltip } from 'bootstrap-vue'

import LTable from '@/views/components/LTable/LTable.vue'
import tableConfig from '../roleConfig'

export default {

  name: 'RoleList',
  components: {
    LTable,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },
  methods: {
    refetchData() {
      this.$refs.lTableRef.refetchData()
    },
    remove(data) {
      const { id } = data

      this.confirmNotification(this, [id], `${this.MODULE_NAME}/moveToTrash`,
        { text: 'It is possible to revert this', confirmButtonText: 'Yes, deactivate it' }).then(() => {
        this.refetchData()
      })
    },
    update(data) {
      this.$router.push({ name: 'settings-users-role-update', params: { id: data.id } })
    },
    duplicate(data) {
      this.$store.dispatch(`${this.MODULE_NAME}/duplicate`, data).then(() => {
        this.refetchData()
      })
    },
  },
  setup() {
    const MODULE_NAME = 'roles'
    const { tableColumns, ACCESS_ABILITY_FOR_ROLE } = tableConfig()
    return {
      tableColumns,
      MODULE_NAME,
      ACCESS_ABILITY_FOR_ROLE,
    }
  },
}
</script>
