import { can } from '@core/libs/acl/utils'

export default function config() {
  const ACCESS_ABILITY_FOR_ROLE = { action: 'change', subject: 'Roles' }

  // Table Handlers
  const tableColumns = [
    { key: 'actions', label: '', thStyle: { width: can(ACCESS_ABILITY_FOR_ROLE.action, ACCESS_ABILITY_FOR_ROLE.subject) ? '10%' : '0%' } },
    { key: 'name', label: 'Role Name' },
    { key: 'is_active', label: 'Status' },
  ]

  return {
    tableColumns,
    ACCESS_ABILITY_FOR_ROLE,
  }
}
